/*------------------------------------------------------------------*/
/*	BASE
/*------------------------------------------------------------------*/

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
font,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td {
	background: transparent;
	border: 0;
	margin: 0;
	padding: 0;
	vertical-align: baseline;
	outline: none;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
nav,
section {
	display: block;
}

blockquote {
	quotes: none;
}

blockquote:before,
blockquote:after {
	content: "";
	content: none;
}
ul,
ol {
	margin: 0px;
	padding: 0;
}
ul li,
ol li {
	margin: 0px;
}
img {
	vertical-align: middle;
	max-width: 100%;
}
a {
	cursor: pointer !important;
}
iframe {
	max-width: 100%;
}
del {
	text-decoration: line-through;
}
a,
i {
	border: none;
}

/*------------------------------------------------------------------*/
/*	FORM ELEMENTS
/*------------------------------------------------------------------*/

textarea {
	border-radius: 0;
	font-size: 13px;
	resize: vertical;
	font-family: "Poppins";
	letter-spacing: 1.2px !important;

	-webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
	-moz-box-sizing: border-box; /* Firefox, other Gecko */
	-ms-box-sizing: border-box; /* IE 10 + */
	box-sizing: border-box; /* Opera/IE 8+ */

	line-height: 1.4;
	padding: 10px !important;
	width: 100%;
	border: 1px solid #eee;

	-webkit-transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
	-ms-transition: all 0.3s ease;
	-o-transition: all 0.3s ease;
	transition: all 0.3s ease;
}
input[type="text"],
input[type="password"],
input[type="datetime"],
input[type="datetime-local"],
input[type="date"],
input[type="month"],
input[type="time"],
input[type="week"],
input[type="number"],
input[type="email"],
input[type="url"],
input[type="search"],
input[type="tel"],
input[type="color"],
.uneditable-input {
	border-radius: 0;
	font-size: 13px;
	min-width: auto;
	letter-spacing: 1.4px;

	-webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
	-moz-box-sizing: border-box; /* Firefox, other Gecko */
	-ms-box-sizing: border-box; /* IE 10 + */
	box-sizing: border-box; /* Opera/IE 8+ */

	font-family: "Poppins";

	line-height: 1.6;
	padding: 10px 15px !important;
	height: 44px;
	vertical-align: middle;
	border: 1px solid #eee;

	-webkit-transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
	-ms-transition: all 0.3s ease;
	-o-transition: all 0.3s ease;
	transition: all 0.3s ease;
}

textarea:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="time"]:focus,
input[type="week"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="color"]:focus,
.uneditable-input:focus {
	border-color: #ccc;
	background: #eee;
}

input[type="submit"] {
	padding: 13px 15px;
	height: 40px;
	line-height: 1;
	text-transform: uppercase;
	font-size: 13px;
	font-weight: 600;
	text-decoration: none;
	letter-spacing: 0.5px;
	border: none;
	cursor: pointer;
	color: #fff;
	background: #777;

	-webkit-transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
	-ms-transition: all 0.3s ease;
	-o-transition: all 0.3s ease;
	transition: all 0.3s ease;
}
input[type="submit"]:hover {
	background: #444;
}
select {
	padding: 8px;
	border: 2px solid #eee;
}
input[type="button"] {
	cursor: pointer;
	-webkit-transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
	-ms-transition: all 0.3s ease;
	-o-transition: all 0.3s ease;
	transition: all 0.3s ease;
}

// Modal css
.mymodal {
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	border: 1px solid #ccc;
	background: #fff;
	overflow: initial;
	border-radius: 4px;
	outline: none;
}

.myoverlay {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(0, 0, 0, 0.75);
	z-index: 99;
}

.ReactModal__Overlay {
	opacity: 0;
	transition: all 0.3s ease;
}

.ReactModal__Overlay--after-open {
	opacity: 1;
}

.ReactModal__Overlay--before-close {
	opacity: 0;
}
* {
	-webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
	-moz-box-sizing: border-box; /* Firefox, other Gecko */
	box-sizing: border-box; /* Opera/IE 8+ */
}

.close-modal {
	background: transparent;
	border: none;
	display: flex;
	width: 45px;
	height: 45px;
	position: absolute;
	z-index: 4;
	right: -50px;
	top: -32px;
	@media screen and (max-width: 1199px) {
		right: 0px;
		top: -60px;
	}
	@media screen and (max-width: 575px) {
		right: -10px;
		top: -37px;
	}
	img {
		width: 45px;
		height: 45px;
		cursor: pointer;
		position: absolute;
		filter: invert(1);
		transition: 0.3s;
		@media screen and (max-width: 575px) {
			width: 30px;
			height: 30px;
		}
	}
	&:hover {
		img {
			filter: invert(0.8);
		}
	}
}
